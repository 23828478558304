import { createNativeStackNavigator } from "@react-navigation/native-stack";

import HomeScreen from "../screens/dashboard/HomeScreen";
import NotificationScreen from "../screens/dashboard/NotificationScreen";

import useScreenType from "../hooks/useScreenType";
import {
  AppointmentsNavigationFlow,
  GoalsNavigationFlow,
  GoalsNavigationType,
  Header,
  ScreenOrientation,
  YourCareTeamNavigationFlow,
  YourCareTeamNavigationType
} from "./NavigationUtils";
import VideoCallComponent from "../components/VideoCallComponent";
import LocalizedStrings from "../helpers/LocalizedStrings";
import USPSTrackingComponent from "../components/USPSTrackingComponent";
import MissingLegalFormsScreen from "../screens/auth/MissingLegalFormsScreen";

export type DashboardStackParamList = {
  Dashboard: undefined;
  Notification: undefined;

  USPSTracking: { trackingNumber: string };
  VideoCall: { code: string };
  MissingLegalForms: { code: string };
} & YourCareTeamNavigationType &
  GoalsNavigationType;

const Stack = createNativeStackNavigator<DashboardStackParamList>();
const DashboardStackNavigator = ({ navigation, route }) => {
  const { type } = useScreenType();

  return (
    <Stack.Navigator
      initialRouteName="Dashboard"
      screenOptions={{
        header: Header,
        orientation: ScreenOrientation(type)
      }}
    >
      <Stack.Screen
        name="Dashboard"
        component={HomeScreen}
        options={{
          title: LocalizedStrings.screens.home.title
        }}
      />
      <Stack.Screen name="Notification" component={NotificationScreen} />

      {GoalsNavigationFlow(Stack)}
      {YourCareTeamNavigationFlow(Stack)}
      {AppointmentsNavigationFlow(Stack)}

      <Stack.Screen
        name="VideoCall"
        component={VideoCallComponent}
        options={({ navigation }) => {
          return {
            detachPreviousScreen: !navigation.isFocused(),
            orientation: "all",
            headerShown: false,
            title: LocalizedStrings.screens.videoCall.title
          };
        }}
      />

      <Stack.Screen
        name="USPSTracking"
        component={USPSTrackingComponent}
        options={{
          headerShown: false
        }}
      />

      <Stack.Screen
        name="MissingLegalForms"
        component={MissingLegalFormsScreen}
        options={{
          title: LocalizedStrings.screens.missingLegalForms.title,
          header: (props) => (
            <Header
              {...props}
              hideMenuToggleOnTablet={true}
              hideBackButton={true}
            />
          )
        }}
      />
    </Stack.Navigator>
  );
};

export default DashboardStackNavigator;
