import { useEffect, useState } from "react";
import { View, Platform, useWindowDimensions } from "react-native";
import {
  NavigationContainer,
  NavigationState,
  useNavigationContainerRef
} from "@react-navigation/native";
import { makeStyles, useTheme } from "@rneui/themed";
import { useSelector } from "react-redux";
import Toast, { ToastConfigParams } from "react-native-toast-message";
import Feather from "react-native-vector-icons/Feather";
import useAppState from "react-native-appstate-hook";

import { RootState, useAppDispatch } from "common/redux";
import LinkingHelper from "common/helpers/LinkingHelper";
import EnvVars from "common/config/EnvVars";
import {
  AnalyticsHelper_logScreenView,
  AnalyticsHelper_setIsTest
} from "common/helpers/firebase/AnalyticsHelper";
import { useNetInfo } from "@react-native-community/netinfo";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";

import { logOut, onAppStart } from "common/redux/AuthSlice";

import AuthStackNavigator from "./AuthStackNavigator";
import TabNavigator, { TabsParamList } from "./TabNavigator";
import useViewStyles from "../components/ui/styles/useViewStyles";
import StorageHelper from "common/helpers/StorageHelper";
import StorageEnum from "common/enums/StorageEnum";
import {
  AxiosService_onFocus,
  AxiosService_onFocusLost,
  AxiosService_onOffline,
  AxiosService_onOnline
} from "common/services/AxiosService";
import {
  SentryHelper_isLastEventIdUnexpected,
  SentryHelper_lastEventId
} from "common/helpers/SentryHelper";

import { setBiometricsEnabled } from "common/redux/SettingsSlice";

import useLinkingConfiguration from "../hooks/useLinkingConfiguration";
import { Biometrics_isSupported } from "../helpers/BiometricsHelpers";
import BiometricsValidationScreen from "../screens/auth/BiometricsValidationScreen";
import useScreenType, {
  ScreenOrientationTypeEnum,
  ScreenTypeEnum
} from "../hooks/useScreenType";
import DrawerNavigator from "./drawer/DrawerNavigator";
import ActivityIndicator from "../components/ui/ActivityIndicator";
import useKeyboard from "../hooks/useKeyboard";
import RotateDevice from "../components/ui/navigation/RotateDevice";
import ResponsiveBreakpoints from "../constants/ResponsiveBreakpoints";

import UserFeedbackModal from "../components/modal/UserFeedbackModal";
import { InAppUpdates_checkAppUpdate } from "../helpers/InAppUpdatesHelper";
import Spacing from "../components/ui/Spacing";
import useTextStyles from "../components/ui/styles/useTextStyles";

import Text from "../components/ui/Text";
import ReduxModal from "../components/modal/ReduxModal";
import { getNameOrUsername } from "common/helpers/helpers";
import MissingLegalFormsScreen from "../screens/auth/MissingLegalFormsScreen";
import Button from "../components/ui/Button";
import { RemoteNotificationType } from "common/helpers/firebase/PushNotificationsHelper.common";
import useGetExpiringConsents from "../hooks/useGetExpiringConsents";

export const FULL_SCREEN_ROUTES = [
  "ChartFullModal",
  "VideoCall",
  "LoginVideoCall",
  "MissingLegalForms"
];

const isDrawerNavigator = (width: number, type: ScreenTypeEnum) => {
  return (
    width > ResponsiveBreakpoints.SIDEBAR_DRAWER_WIDTH_BREAKPOINT &&
    type !== ScreenTypeEnum.PHONE
  );
};

interface ToastProps {
  remoteMessage: RemoteNotificationType;
  onActionPressed: () => void;
}

const RenderToast = (params: ToastConfigParams<ToastProps>) => {
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);
  const viewStyles = useViewStyles();

  const { width } = useWindowDimensions();
  const { type, orientation } = useScreenType();

  const { theme } = useTheme();
  const { remoteMessage, onActionPressed } = params.props;

  const isDrawerPermanent =
    orientation === ScreenOrientationTypeEnum.LANDSCAPE ||
    width > ResponsiveBreakpoints.SIDEBAR_DRAWER_WIDTH_PERMANENT_BREAKPOINT ||
    Platform.OS === "web";

  const isDrawer = isDrawerNavigator(width, type) && isDrawerPermanent;

  if (remoteMessage) {
    return (
      <View
        style={[
          viewStyles.cardContainer,
          styles.card,
          { alignSelf: "stretch", marginLeft: 10, marginRight: 10 },
          isDrawer && { marginLeft: 370 }
        ]}
      >
        <Feather name="info" size={30} color={theme.colors.darkGreyBlue} />
        <View style={{ flex: 1 }}>
          {params.text1?.length > 0 && (
            <Text caps style={styles.toastText}>
              {params.text1}
            </Text>
          )}
          {params.text2?.length > 0 && (
            <Text bodySmall style={styles.toastText}>
              {params.text2}
            </Text>
          )}
        </View>
        {onActionPressed && <Button title="Open" onPress={onActionPressed} />}
      </View>
    );
  }

  return (
    <View style={[viewStyles.cardContainer, styles.card]}>
      <Feather name="info" size={30} color={theme.colors.darkGreyBlue} />
      <View>
        {params.text1?.length > 0 && (
          <Text bodySmall style={styles.toastText}>
            {params.text1}
          </Text>
        )}
        {params.text2?.length > 0 && (
          <Text bodySmall style={styles.toastText}>
            {params.text2}
          </Text>
        )}
      </View>
    </View>
  );
};

// Useful for dev (only localhost). It is disabled for builds (dev, staging, prod)
// https://reactnavigation.org/docs/state-persistence
const isWeb = Platform.OS === "web";
const NAVIGATION_STATE_SAVE_DISABLED =
  EnvVars.REACT_APP_SAVE_NAVIGATION_STATE !== "true" || isWeb;

const Router = () => {
  const insets = useSafeAreaInsets();
  const netInfo = useNetInfo();
  const dispatch = useAppDispatch();
  const { isLoggedIn, currentBuildEnv } = useSelector(
    (state: RootState) => state.auth
  );
  const { biometricsEnabled } = useSelector(
    (state: RootState) => state.settings
  );

  const textStyles = useTextStyles();
  const styles = useStyles(insets);

  const { isExpired, member } = useGetExpiringConsents();

  const { height, width } = useWindowDimensions();
  const keyboardVisible = useKeyboard();
  const navigationRef = useNavigationContainerRef<TabsParamList>();
  const linkingConfiguration = useLinkingConfiguration();

  const { type, orientation } = useScreenType();

  const [isReady, setIsReady] = useState<boolean>(
    NAVIGATION_STATE_SAVE_DISABLED
  );
  const [downloadProgress, setDownloadProgress] = useState<number>(null);
  const [biometricsSuccess, setBiometricsSuccess] = useState<boolean>(false);
  const [showRotateDevice, setShowRotateDevice] = useState<boolean>(false);
  const [userFeedbackModalVisible, setUserFeedbackModalVisible] =
    useState<boolean>(false);

  const [initialState, setInitialState] = useState<NavigationState>();
  const [currentRouteName, setCurrentRouteName] = useState<string>();

  const { appState } = useAppState();

  const restoreNavigationState = async () => {
    if (isReady) return;

    const savedStateString = await StorageHelper.getItem(
      StorageEnum.NAVIGATION_STATE
    );
    const state = savedStateString ? JSON.parse(savedStateString) : undefined;
    if (state !== undefined) setInitialState(state);
    setIsReady(true);
  };

  const onProgressChange = (progress: number | null) => {
    setDownloadProgress(progress);
  };

  useEffect(() => {
    InAppUpdates_checkAppUpdate(onProgressChange);
  }, []);

  useEffect(() => {
    restoreNavigationState();
  }, []);

  useEffect(() => {
    if (netInfo.isInternetReachable) AxiosService_onOnline();
    else AxiosService_onOffline();
  }, [netInfo]);

  useEffect(() => {
    dispatch(onAppStart());

    if (isLoggedIn) {
      StorageHelper.setItem(
        StorageEnum.AUTHENTICATION_USER_NAME,
        getNameOrUsername(member?.patient, false, false)
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && member) {
      StorageHelper.setItem(
        StorageEnum.AUTHENTICATION_USER_NAME,
        getNameOrUsername(member?.patient, false, false)
      );
    }
  }, [isLoggedIn, member]);

  useEffect(() => {
    if (member === undefined) return;
    AnalyticsHelper_setIsTest(member?.patient?.is_test);
  }, [member]);

  useEffect(() => {
    if (appState === "active") AxiosService_onFocus();
    else AxiosService_onFocusLost();

    if (
      appState === "active" &&
      currentBuildEnv !== EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV
    ) {
      // log out the user if the build environment has changed
      dispatch(logOut(true));
    }
    Biometrics_isSupported().then((supported: boolean) => {
      // This is to prevent prompting when we enable the biometrics on settings on that session.
      if (biometricsEnabled === false) {
        setBiometricsSuccess(true);
      }
      dispatch(setBiometricsEnabled(supported && biometricsEnabled));
    });

    if (appState === "background") setBiometricsSuccess(false);
  }, [appState]);

  const biometryPrompt =
    isLoggedIn && biometricsEnabled && biometricsSuccess === false;

  const AuthenticatedNavigator = isDrawerNavigator(width, type)
    ? DrawerNavigator
    : TabNavigator;

  useEffect(() => {
    let timeout;
    // https://copilotiq.atlassian.net/browse/ENG-3256
    // When the height is too low, the auth flow breaks
    if (
      Platform.OS === "web" &&
      type === ScreenTypeEnum.PHONE &&
      orientation === ScreenOrientationTypeEnum.LANDSCAPE &&
      !FULL_SCREEN_ROUTES.includes(currentRouteName) &&
      keyboardVisible === false
    ) {
      timeout = setTimeout(() => {
        setShowRotateDevice(true);
      }, 500);
    } else {
      if (timeout) clearTimeout(timeout);
      setShowRotateDevice(false);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [currentRouteName, orientation, type, keyboardVisible]);

  useEffect(() => {
    handleStateChange();
  }, [navigationRef, isReady]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Give time for sentry to sync
      const lastSentryEvent = SentryHelper_lastEventId();
      if (
        isReady === false ||
        lastSentryEvent === undefined ||
        SentryHelper_isLastEventIdUnexpected(lastSentryEvent) === false
      )
        return;

      setUserFeedbackModalVisible(true);

      return () => clearTimeout(timeout);
    }, 2000);
  }, [isReady]);

  const handleStateChange = () => {
    if (navigationRef?.isReady() === false) return;
    const route = navigationRef.getCurrentRoute();
    if (route === undefined) return;
    const currentRouteName = route.name;
    setCurrentRouteName(currentRouteName);
    AnalyticsHelper_logScreenView(currentRouteName);
  };

  if (!isReady) {
    return <ActivityIndicator style={styles.flex1} size="large" />;
  }

  if (showRotateDevice) {
    return <RotateDevice />;
  }

  if (downloadProgress) {
    const progressString = Math.round(downloadProgress * 100) + " %";
    return (
      <View style={styles.progressBarContainer}>
        <Text caps style={textStyles.colorDarkGreyBlue}>
          Downloading Update {progressString}
        </Text>
        <Spacing vertical={2} />

        <View style={styles.progressBarInnerContainer}>
          <View style={[styles.progressBarTint, { flex: downloadProgress }]} />

          <View
            style={[
              styles.progressBarBackground,
              {
                flex: 1 - downloadProgress
              }
            ]}
          />
        </View>
      </View>
    );
  }
  // https://stackoverflow.com/questions/67100423/accomodate-for-browser-toolbar-in-mobile-layout
  return (
    <View
      style={
        Platform.OS === "web" && keyboardVisible === false
          ? [styles.background, { height: height }]
          : [styles.background, styles.flex1]
      }
    >
      <UserFeedbackModal
        visible={userFeedbackModalVisible}
        onRequestClose={() => setUserFeedbackModalVisible(false)}
      />

      <NavigationContainer
        ref={navigationRef}
        initialState={initialState}
        linking={linkingConfiguration}
        documentTitle={{
          formatter: (options, route) => `CopilotIQ`
        }}
        onStateChange={(state) => {
          StorageHelper.setItem(
            StorageEnum.NAVIGATION_STATE,
            JSON.stringify(state)
          );
          handleStateChange();
        }}
      >
        {isLoggedIn ? <AuthenticatedNavigator /> : <AuthStackNavigator />}

        {biometryPrompt && (
          <View style={styles.biometricsContainer}>
            <BiometricsValidationScreen
              onSuccess={() => setBiometricsSuccess(true)}
              onFailure={() => setBiometricsSuccess(false)}
            />
          </View>
        )}

        {isExpired && (
          <View style={styles.biometricsContainer}>
            <MissingLegalFormsScreen navigation={navigationRef} />
          </View>
        )}

        <ReduxModal />
        <Toast
          config={{
            info: RenderToast,
            // https://github.com/calintamas/react-native-toast-message/issues/300#issuecomment-1128965154
            success: (props) => {
              return props.isVisible ? undefined : null;
            }
          }}
        />
        <LinkingHelper />
      </NavigationContainer>
    </View>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  return {
    card: {
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: "row",
      gap: 10,
      alignItems: "center",
      marginVertical: 0
    },
    toastText: {
      color: theme.colors.darkGreyBlue
    },
    flex1: {
      flex: 1
    },
    background: {
      backgroundColor: theme.colors.background
    },
    biometricsContainer: {
      position: "absolute",
      top: insets.top,
      bottom: insets.bottom,
      left: 0,
      right: 0
    },
    progressBarContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    progressBarInnerContainer: {
      flexDirection: "row",
      width: 300,
      height: 50
    },
    progressBarTint: {
      backgroundColor: theme.colors.primary,
      overflow: "hidden",
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    },
    progressBarBackground: {
      backgroundColor: theme.colors.grey4,
      overflow: "hidden",
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    }
  };
});

export default Router;
