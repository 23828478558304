import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { makeStyles, useTheme } from "@rneui/themed";

import StorageHelper from "common/helpers/StorageHelper";
import StorageEnum from "common/enums/StorageEnum";

import LandingScreen from "../screens/auth/LandingScreen";
import PhoneInputScreen from "../screens/auth/PhoneInputScreen";
import SMSCodeScreen from "../screens/auth/SMSCodeScreen";
import BiometricsScreen from "../screens/auth/BiometricsScreen";
import ReauthenticationScreen from "../screens/auth/ReauthenticationScreen";
import TurnOnNotificationsScreen from "../screens/auth/TurnOnNotificationsScreen";
import useScreenType from "../hooks/useScreenType";
import { Header, ScreenOrientation } from "./NavigationUtils";
import MissingLegalFormsScreen from "../screens/auth/MissingLegalFormsScreen";
import VideoCallComponent from "../components/VideoCallComponent";
import LocalizedStrings from "../helpers/LocalizedStrings";
import JoinVideoCallScreen from "../screens/auth/JoinVideoCallScreen";
import USPSTrackingComponent from "../components/USPSTrackingComponent";
import { isTruthy } from "common/helpers/helpers";

export type AuthStackParamList = {
  Reauthentication: { userName: string };
  Landing: undefined;
  PhoneInput: undefined;
  SMSCode: { phoneNumber: string; birthDate: string; preLoginId: string };
  MissingLegalForms: { code: string };
  Biometrics: undefined;
  TurnOnNotifications: undefined;
  JoinVideoCall: undefined;

  USPSTracking: { trackingNumber: string };
  LoginVideoCall: { code: string };
};

const Stack = createNativeStackNavigator<AuthStackParamList>();
const AuthStackNavigator = () => {
  const { theme } = useTheme();

  const [userName, setUserName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const styles = useStyles();
  const { type } = useScreenType();

  useEffect(() => {
    StorageHelper.getItem(StorageEnum.AUTHENTICATION_USER_NAME).then(
      (userName) => {
        setUserName(userName);
        setLoading(false);
      }
    );
  }, []);
  if (loading) {
    return (
      <ActivityIndicator
        style={styles.flex1}
        size="large"
        color={theme.colors.tealBlue}
      />
    );
  }

  return (
    <Stack.Navigator
      // Set env  REACT_APP_SAVE_NAVIGATION_STATE = false to test this
      initialRouteName={isTruthy(userName) ? "Reauthentication" : "Landing"}
      screenOptions={{
        header: (props) => <Header {...props} hideMenuToggleOnTablet={true} />,
        orientation: ScreenOrientation(type)
      }}
    >
      <Stack.Screen
        name="Reauthentication"
        component={ReauthenticationScreen}
        initialParams={{ userName }}
      />
      <Stack.Screen name="Landing" component={LandingScreen} />
      <Stack.Screen
        name="PhoneInput"
        component={PhoneInputScreen}
        options={{ title: LocalizedStrings.screens.phoneInput.title }}
      />
      <Stack.Screen
        name="SMSCode"
        component={SMSCodeScreen}
        options={{ title: LocalizedStrings.screens.smsCode.title }}
      />

      <Stack.Screen
        name="MissingLegalForms"
        component={MissingLegalFormsScreen}
        options={{
          title: LocalizedStrings.screens.missingLegalForms.title,
          header: (props) => (
            <Header
              {...props}
              hideMenuToggleOnTablet={true}
              hideBackButton={true}
            />
          )
        }}
      />

      <Stack.Screen
        name="Biometrics"
        component={BiometricsScreen}
        options={{ title: LocalizedStrings.screens.biometrics.title }}
      />

      <Stack.Screen
        name="TurnOnNotifications"
        component={TurnOnNotificationsScreen}
        options={{
          title: LocalizedStrings.screens.turnOnNotifications.title
        }}
      />

      <Stack.Screen
        name="JoinVideoCall"
        component={JoinVideoCallScreen}
        options={{
          title: LocalizedStrings.screens.joinVideoCall.title
        }}
      />

      <Stack.Screen
        name="LoginVideoCall"
        component={VideoCallComponent}
        options={({ navigation }) => {
          return {
            detachPreviousScreen: !navigation.isFocused(),
            orientation: "all",
            headerShown: false,
            title: LocalizedStrings.screens.videoCall.title
          };
        }}
      />

      <Stack.Screen
        name="USPSTracking"
        component={USPSTrackingComponent}
        options={{
          headerShown: false
        }}
      />
    </Stack.Navigator>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    }
  };
});

export default AuthStackNavigator;
