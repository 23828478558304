import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { RootState } from "common/redux";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useGetExpiringConsents = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const {
    data: member,
    isLoading: isMemberLoading,
    isSuccess: isMemberSuccess
  } = useGetAuthenticatedMember(!isLoggedIn, [
    MemberLinkedEntitiesEnum.MISSING_LEGAL_FORMS,
    MemberLinkedEntitiesEnum.ACCEPTED_LEGAL_FORMS,
    MemberLinkedEntitiesEnum.EXPIRING_CONSENTS
  ]);

  const missing_consents = useMemo(() => {
    return member?.missing_legal_forms
      ?.filter((item) => item.consent_type !== undefined)
      .map((item) => item.consent_type);
  }, [member]);

  const filteredExpiringConsents = useMemo(() => {
    return member?.expiring_consents?.filter((item) => item.is_latest);
  }, [member]);

  const isExpired = isMemberSuccess && missing_consents?.length > 0;
  const isExpiringSoon = filteredExpiringConsents?.length > 0;

  return {
    isExpired,
    isExpiringSoon,
    isLoading: isMemberLoading,
    member
  };
};

export default useGetExpiringConsents;
